import { Component, OnInit, Renderer2, Inject, ViewChild } from '@angular/core';
import { ThemeSettingsService } from '../../settings/theme-settings.service';
import { DOCUMENT } from '@angular/common';
import { NavbarService } from 'src/app/_services/navbar.service';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { MenuSettingsService } from '../../settings/menu-settings.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { OpenAIService } from 'src/app/_services/dispo/openai.service';
import { SpeechSynthesisService } from 'src/app/_services/dispo/speech-synthesis.service';
import { SpeechRecognitionService } from 'src/app/_services/dispo/speech-recognition.service';
//import OpenAI from "openai";


declare var require: any;
const colors = require('../../../../assets/data/customizer/customizerColor.json');
//const openai = new OpenAI();

@Component({
  selector: 'app-horizontal-customizer',
  templateUrl: './horizontal-customizer.component.html',
  styleUrls: ['./horizontal-customizer.component.css']
})
export class HorizontalCustomizerComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;
  public _themeCustomizerConfig: any;
  public _menuCustomizerConfig: any;
  private _themeSettingsConfig: any;
  public selectColorClass: string;
  public isCollapsedMenu = false;
  public isRightSideIcons = false;

  activeIdString = 'light';

  messages = [];
  userInput = '';
  completion: any;
  text: any;



  public config: PerfectScrollbarConfigInterface = { wheelPropagation: false };

  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;


  constructor(private _renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private navbarService: NavbarService,
    private openAIService: OpenAIService,
    private _themeSettingsService: ThemeSettingsService,
    private _menuSettingsService: MenuSettingsService, 
    private speechSynthesisService: SpeechSynthesisService,
    private speechRecognitionService: SpeechRecognitionService
  ) {
    this._unsubscribeAll = new Subject();
  }
  ngOnInit() {

    this._themeSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._themeCustomizerConfig = config;
      });
    this._themeSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._themeSettingsConfig = config;
      });
  }

  speak() {
    this.speechSynthesisService.speak(this.userInput);
  }

  listen() {
    this.speechRecognitionService.listen().then((text) => {
      console.log('test');
      this.userInput = text;
    }).catch((error) => {
      console.error(error);
    });
  }

  stop() {
    this.speechRecognitionService.stop();
  }

  getCompletion(prompt: string): void {
    this.openAIService.createChatCompletion(prompt).subscribe(data => {
      this.completion = data;
    }, error => {
      console.error('Error getting completion:', error);
    });
  }

  toggleCustomizer(event) {
    const element = document.getElementById('customizer');
    if (element && element.classList.contains('open')) {
      this._renderer.removeClass(element, 'open');
    } else {
      this._renderer.addClass(element, 'open');
    }
  }

  sendPrompt() {
    if (!this.userInput) return;

    // Füge die Benutzereingabe zum Chatverlauf hinzu
    this.messages.push({ text: this.userInput, sender: 'user' });

    // Sende die Eingabe an den OpenAI-Service und verarbeite die Antwort
    this.openAIService.getResponse(this.userInput).subscribe(response => {
      // Füge die Antwort der KI zum Chatverlauf hinzu
      let text: any;
      text = response.choices[0]
      let message = text.message.content;
      this.messages.push({ text: message, sender: 'ai' });
    });

    // Setze das Eingabefeld zurück
    this.userInput = '';
  }










 
}
