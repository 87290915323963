// openai.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OpenAIResponse } from 'src/app/_interfaces/openAIResponse';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})


export class OpenAIService {
  private baseURL: string = "https://api.openai.com/v1/chat/completions";
  private apiKey = "sk-proj-3bnVBUb2pkfwd0QmItR8T3BlbkFJIEwcEIU2LuLnG9CgTGT5";
  private apiKey2 = "Bearer sk-proj-mclAczrIOpMpsS10TJrgT3BlbkFJ08ydRDE89UjDLXERYjkl";
  private mh3prof = "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";


  constructor(private http: HttpClient) {
   
  }

  getResponse(prompt: string): Observable<OpenAIResponse> {
    console.log('test');
    let api = 'Bearer ' + this.apiKey2
   
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer sk-proj-mclAczrIOpMpsS10TJrgT3BlbkFJ08ydRDE89UjDLXERYjkl',
      'OpenAI-Organization': 'org-IGx7DdZgPHKYMRrBtdQCqiRz',
      'OpenAI-Project': 'proj_HByzwT029Y32v7Ef8o6kDabT',
      'Allow-Origin': '*',
      'Connection': 'keep-alive'
    });
    
  
    const body = {
      "model": "gpt-3.5-turbo",
      "max_tokens": 200,
      "messages": [
        {
          "role": "user",
          "content": prompt
        }
      ]
    };
    //return null;
    return this.http.post<OpenAIResponse>(this.baseURL, body, { headers: headers });
  }
  

  getPromt(prompt: string) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Bearer Ihr-API-Schlüssel' // Ersetzen Sie 'Ihr-API-Schlüssel' mit Ihrem tatsächlichen API-Schlüssel
    });

    const requestBody = {
      prompt: prompt,
      max_tokens: 150
    };

    return this.http.post(this.baseURL, requestBody, { headers: headers });
  }

  createChatCompletion(prompt: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer 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'   // Ersetze mit deinem API-Schlüssel
    });

    const body = {
      "model": "gpt-3.5-turbo",
      "max_tokens": 150,
      "messages": [
          {
              "role": "user",
              "content": "Say this is a test!"
          }
      ]
  }

    return this.http.post(this.baseURL, body, { headers: headers });
  }
}
